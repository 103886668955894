<template>
  <div class="container-fluid px-xl-4 py-4">
  <div class="part-list">
    <b-row>
      <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12" v-if="showScanner">
        <StreamBarcodeReader @decode="onDecode" @error="onError"></StreamBarcodeReader>
      </b-col>
    </b-row>

    <div class="search-container">
      <div class="add-btn d-flex gap-5 align-items-start">
        <label class="m-0 mt-2" style="min-width: 130px;">{{ $t('checkoutParts.select_daterange') }} : </label>
        <date-range-picker
          ref="picker"
          opens="right"
          :ranges="ranges()"
          :locale-data="{
            direction: 'ltr',
            format: 'MM-DD-yyyy',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 0
          }"
          @update="onFilter"
          v-model="dateRange"
          :alwaysShowCalendars="false       "
          controlContainerClass="noneBorder"
          style="min-width:200px;"
        >
          <template v-slot:input="picker">
            <b-form-input
              type="text"
              hide-details="auto"
              :label="$t('invoice.createForm.date')"
              append-icon="mdi-calendar"
              readonly
              elevation="0"
              height="40"
              outlined
              :value="setDates(picker)"
            ></b-form-input>
          </template>
        </date-range-picker><br/>
        <!-- <span class="hint--red">(By default data is showing for current day.)</span> -->
      </div>
      <div class="s-c-right">
        <div class="search-group">
          <input placeholde="Search..." type="text" id="dataTableSearch" ref="dataTableSearch" />
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
              stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 19L14.7 14.7" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div class="add-btn d-flex">
           <b-button class="btn custom-btn pattern-btn" @click="showScanner=!showScanner"><span>{{ $t('checkoutParts.scan_barcode') }} </span></b-button>
      </div>


      </div>
       
        </div>
    
    <div class="custom-table">
      <table
        id="example"
        class="display responsive nowrap"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th class="desktop tablet-p tablet-l mobile-l mobile-p"> Id</th>
            <th class="desktop tablet-l">{{ $t('checkoutParts.checkout_date') }}</th>
            <th class="desktop tablet-p tablet-l mobile-l mobile-p">{{ $t('addPart.part_name') }}</th>
            <th class="desktop tablet-p tablet-l">{{ $t('AddCar.year') }} / {{ $t('AddCar.make') }} / {{ $t('AddCar.model') }} / {{ $t('AddCar.miles') }} </th>
            <th>{{ $t('addPart.recommendedSales') }}</th>
            <th class="none">{{ $t('partsList.warranty') }}</th>
            <th class="desktop tablet-p tablet-l mobile-l mobile-p">{{ $t('partsList.action') }}</th>
          </tr>
        </thead>
      </table>
    </div>
    <b-modal
      ref="my-modal"
      centered
      no-close-on-backdrop
      hide-footer
      :title="$t('checkoutParts.undo_confirmation')"
    >
      <div class="d-block text-center">
        <svg class="icon-warning" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0001 29.3337C23.3639 29.3337 29.3334 23.3641 29.3334 16.0003C29.3334 8.63653 23.3639 2.66699 16.0001 2.66699C8.63628 2.66699 2.66675 8.63653 2.66675 16.0003C2.66675 23.3641 8.63628 29.3337 16.0001 29.3337Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 17L10 14L13 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 22V16.9091C22 16.1376 21.7103 15.3976 21.1945 14.8521C20.6788 14.3065 19.9793 14 19.25 14H11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        <h5>{{ $t('checkoutParts.undo_confirmation_message') }}</h5>
      </div>
      <div class="d-block text-center">
        <b-button
          class="btn mt-4 btn custom-btn custom-danger btn-outline-danger"
          variant="outline-danger"
          @click="undoFromCheckout()"
          >{{ $t('checkoutParts.confirm') }}</b-button
        >
        <b-button class="btn ml-2 mt-4 btn custom-btn pattern-btn btn-secondary" @click="dialogDelete = false">
          <span>{{
          $t("confirmationDialog.cancel")
        }}</span></b-button>
      </div>
    </b-modal>
  </div>
</div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import { CHECKOUT_PARTLIST } from "@/constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";
import { showSuccess, showFailure } from "../eventbus/action.js";
import tokenService from "./../services/token.service.js";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showScanner: false,
      dialogDelete: false,
      selectedId: null,
      totalPrice: "0",
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      country: 'us'
    };
  },
  watch: {
    dialogDelete(val) {
      if (val) {
        this.$refs["my-modal"].show();
      } else {
        this.$refs["my-modal"].hide();
      }
    }
  },
  async mounted() {
    await this.getPartList();
    await this.addListeners();
    this.country = await this.user?.user_detail?.country || 'us';
  },
  computed: {
    ...mapGetters({
      addCheckoutPartInfo: "partlist/getAddCheckoutPart",
      user: "user/getUser",
    }),
  },
  methods: {
    ...mapActions({
      addCheckoutPart: "partlist/addCheckoutPart",
    }),
    async getPartList() {
      let self = this;

      try {
        this.$store.commit("setLoader", true);
        this.table = $("#example").DataTable({
          order: [[0, 'desc']],
          ajax: {
            url: process.env.VUE_APP_API_HOST + CHECKOUT_PARTLIST,
            type: "GET",
            data: {
              from: moment(self.dateRange.startDate).format("YYYY-MM-DD"),
              to: moment(self.dateRange.endDate).format("YYYY-MM-DD"),
            },
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
            dataSrc: function ( json ) {
              self.totalPrice = json.total_price.toString();
              return json.data;
            }  
          },
          columns: [
          {
              orderable: false,
              data: function (row) {
                return (`<span class="collespe-wrap">${row.id}</span>`);
              },
            },
            {
              data: function (row) {
                return (
                  self.$options.filters.DateZoneFilter(
                    moment(row.checkout_at)
                  ) || "N/A"
                );
              },
            },
            {
              data: function (full) {
                return full.part_name || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return (
                  full.year +
                  "/" +
                  full.make +
                  "/" +
                  full.model +
                  
                  (full.miles ? "/" + (
                        Number(full.miles)
                          .toFixed(0)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                  ) : '')
                );
              },
            },
            {
              data: function (row) {
                return self.$options.filters.Currencyfilter(row.recommended_sale_price, self.country);
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.warranty_days || "N/A";
              },
            },
            {
              className: "action",
              orderable: false,
              data: function (row) {
                  return `<ul class="action-list">
                <li>
                  <a
                    data-secret = ${row.id}
                    class="act-icon delete-icon edit-btn tooltips"
                    data-tooltip="Undo"
                  >
        

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 9H16C17.3261 9 18.5979 9.52678 19.5355 10.4645C20.4732 11.4021 21 12.6739 21 14C21 15.3261 20.4732 16.5979 19.5355 17.5355C18.5979 18.4732 17.3261 19 16 19H7M3 9L7 5M3 9L7 13" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                  </a>
                </li>
              </ul>`;
              },
            },
          ],
          pagingType: "simple_numbers",
          pageLength: ITEMS_PER_PAGE,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          bInfo : false,
          language: {
            search: '',
            searchPlaceholder: this.$t("commonLayout.search"),
            paginate: {
              previous: this.$t("commonLayout.previous"),
              next: this.$t("commonLayout.next"),
            }
          },
          buttons: [
            "undo",
          ],
        });
        $.fn.dataTable.ext.errMode = "throw";
        // Move the search input to the custom input
        const searchInput = $(this.table.table().container()).find('.dataTables_filter input');
        $(this.$refs.dataTableSearch).replaceWith(searchInput);
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async onDecode(result) {
      this.showScanner = false;
      this.$store.commit("setLoader", true);
      try {
        await this.addCheckoutPart({ id: result, action: 'add' });
        this.table.ajax.reload();
        this.$store.commit("setLoader", false);
        // showSuccess(this.addCheckoutPartInfo.message);
        this.showScanner = false;
      } catch ({ message }) {
        this.showScanner = false;
        this.$store.commit("setLoader", false);
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      }
    },
    async undoFromCheckout() {
      try {
        this.$store.commit("setLoader", true);
        await this.addCheckoutPart({ id: this.selectedId, action: 'undo' });
        this.table.ajax.reload();
        this.dialogDelete = false;
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
        typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
        this.dialogDelete = false;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async addListeners() {
      const self = this;

      $("#example").on("click", ".delete-icon", function (e) {
        self.selectedId = e.currentTarget.dataset.secret;
        self.dialogDelete = true;
        e.preventDefault();
      });
    },
    async onFilter() {
      this.$store.commit("setLoader", true);
      this.table.clear().destroy();
      this.getPartList();
      this.$store.commit("setLoader", false);
    },
    onError(error) {
      console.log(error);
    },
    setDates(picker) {
        let startDate = (picker.startDate ? moment(picker.startDate).format('MM-DD-YYYY')+' - ' : '');
        let endDate = (picker.endDate ? moment(picker.endDate).format('MM-DD-YYYY') : '');
        return startDate+endDate;
    },
    ranges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);
      var y = today.getFullYear(), m = today.getMonth();
      var thisMonthStart = new Date(y, m, 1);
      let thisMonthEnd = new Date(y, m + 1, 0, 11, 59, 59, 999);

      return {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last 7 Days': [new Date(y, m, today.getDate() - 6), today],
        'Last 30 Days': [new Date(y, m, today.getDate() - 29), today],
        'This year': [new Date(y, 0, 1), new Date(y, 11, 31)],
        'Last month': [new Date(y, m - 1, 1), new Date(y, m, 0)],
      }
    },
  },
};
</script>